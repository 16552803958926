

/*桌面端*/
@media only screen and (min-width: 640px){
    .container{
        background-color: #FFFFFF;
        position: relative;
        margin-top: 100px;
    }
}
    /*手机端*/
@media only screen and (max-width: 640px) {
    .container{
        background-color: #FFFFFF;
        position: relative;
        margin-top: 50px;
    }
}